import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Typography, Stack, Box, Checkbox } from '@mui/material';

const ApprovalModal = ({ open, onClose, onApprove }) => {
  const [isAgreed, setIsAgreed] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsAgreed(event.target.checked);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          paddingTop: 0,
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle sx={{ paddingBottom: '8px' }}>Disclaimer</DialogTitle>
      <DialogContent sx={{ paddingTop: 0 }}>
        <Box sx={{ mt: 0 }}>
          <Stack spacing={2}>
            <Typography sx={{ marginTop: '8px' }}>
              Approval for this client signals you have reviewed all the details and you agree to proceed
              on initiating the Frontier setup process.
            </Typography>
            <Typography>
              <Checkbox
                checked={isAgreed}
                onChange={handleCheckboxChange}
                color="primary"
                sx={{ paddingLeft: 0 }}
              />
              Please tick if you agree and are happy to proceed.
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} mt={2}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                ml: "10px",
                backgroundColor: "#EA3E27",
                color: "white",
                textTransform: "none",
                borderRadius: "100px",
                width: "144px",
              }}
              disabled={!isAgreed}
              onClick={() => {
                if (isAgreed) {
                  onApprove();
                  onClose();
                }
              }}
            >
              Approve
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "100px",
                color: "#212121",
                border: "1px solid rgba(0, 0, 0, 0.25)",
                textTransform: "none",
                width: "144px",
              }}
              onClick={() => {
                onClose();
              }}
            >
              Reject
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ApprovalModal;