import React, { useState, useContext } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";

import { useTranslation } from "react-i18next";

import LogoutIcon from "@mui/icons-material/Logout";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { dontForwardTheseProps } from "../../functions/dontForwardTheseProps";
import Constants from "../../Constants";
import HttpContext from "../../services/HttpContext";
import UserContext from "../../services/UserContext";

const StyledListItemButton = styled(ListItemButton)({
  padding: "0",
  paddingTop: "5px",
  paddingBottom: "5px",
});

const StyledListItemIcon = styled(ListItemIcon)({
  width: "76px",
  display: "flex",
  justifyContent: "center",
});

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  flex: "0 1 auto",

  "& .MuiListItemText-primary": {
    color: theme.palette.primary.dark,
  },
}));

const ListContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
});

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => dontForwardTheseProps(["currentLocation", "destinations"], prop),
})(({ color, backgroundColor }) => {
  return { backgroundColor, color, fontSize: "24px", padding: "4px" };
});

const computeColor = (destinations, currentLocation, theme) => {
  let pathBelongsToDestinations = false;
  let color;

  destinations.forEach((path) => {
    if (currentLocation === path) {
      pathBelongsToDestinations = true;
    }
  });

  if (pathBelongsToDestinations) {
    color = theme.palette.primary.darker;
  } else {
    color = theme.palette.primary.light;
  }
  return color;
};

const computeBackgroundColor = (destinations, currentLocation) => {
  let pathBelongsToDestinations = false;
  let backgroundColor;

  destinations.forEach((path) => {
    if (currentLocation === path) {
      pathBelongsToDestinations = true;
    }
  });

  if (pathBelongsToDestinations) {
    backgroundColor = "#FFFFFF";
  } else {
    backgroundColor = "#E8E3DE";
  }
  return backgroundColor;
};

function LeftMenuItems({ open, setOpen }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { clearUser } = useContext(UserContext);
  const { PostRequest } = useContext(HttpContext);
  const logout = () => {
    PostRequest(Constants.REACT_APP_API_LOGOUT).finally(() => {
      clearUser();
      navigate("/admin");
    });
  };

  const navigateToLeads = () => {
    navigate(Constants.CLIENT_ONBOARDING_URL);
    setOpen(false);
  };

  const [onboardingExpanded, setOnboardingExpanded] = useState(false);
  const [accessManagementExpanded, setaccessManagementExpanded] = useState(false);

  if (!open && onboardingExpanded) {
    setOnboardingExpanded(false);
  }

  if (!open && accessManagementExpanded) {
    setaccessManagementExpanded(false);
  }

  return (
    <ListContainer data-testid="menu-list-container">
      <Box>
        <StyledListItemButton
          onClick={navigateToLeads}
          sx={{
            color: computeColor([Constants.CLIENT_ONBOARDING_URL], location.pathname, theme),
            backgroundColor: computeBackgroundColor([Constants.CLIENT_ONBOARDING_URL], location.pathname, theme),
          }}
          data-testid="menu-leads-option"
        >
          <StyledListItemIcon>
            <StyledIconButton disableRipple>
              <AddBusinessIcon />
            </StyledIconButton>
          </StyledListItemIcon>
          <StyledListItemText primary="Leads" />
        </StyledListItemButton>
      </Box>
      <Box>
        <StyledListItemButton
          onClick={logout}
          sx={{
            color: computeColor(["/admin"], location.pathname, theme),
            backgroundColor: computeBackgroundColor(["/admin"], location.pathname, theme),
          }}
          data-testid="menu-logout-option"
        >
          <StyledListItemIcon>
            <StyledIconButton>
              <LogoutIcon />
            </StyledIconButton>
          </StyledListItemIcon>
          <StyledListItemText primary={t("APPLICATION:LEFT_MENU.LOGOUT")} />
        </StyledListItemButton>
      </Box>
    </ListContainer>
  );
}

export default LeftMenuItems;
