import React, { useContext, useState } from "react";
import {
  TableRow,
  TableCell,
  Box,
  CircularProgress,
  Chip,
  Typography,
  Accordion,
  AccordionDetails,
  Grid,
  IconButton,
  styled,
  Button,
  Stack,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Badge,
  Link,
} from "@mui/material";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CircleIcon from "@mui/icons-material/Circle";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { ReactComponent as ClockCountdownIcon } from "./Icons/ClockCountdown.svg";
import {
  convertEnumCaseToHumanReadable,
  convertStatusToText,
  extractRemainingStatusText,
  formatTimestamp,
  getCountryTelephoneCode,
} from "../Utils";
import HttpContext from "../../../services/HttpContext";
import UserContext from "../../../services/UserContext";
import Constants, { CountryCodeToNameMapping } from "../../../Constants";

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 12,
    left: "calc(-50% + 7px)",
    right: "calc(50% + 7px)",
  },
  [`& .${stepConnectorClasses.line}`]: {
    marginTop: "4px",
    border: "1px dashed #909090",
  },
}));

const getStepStatusText = (activeStep, stepIndex, currentStepStatus) => {
  if (stepIndex === activeStep) {
    return convertEnumCaseToHumanReadable(currentStepStatus);
  }
  if (stepIndex < activeStep) {
    return "Complete";
  }
  return "Pending";
};

function TableRowWithDetails({ row, expanded, onRowClick, isEven, onApproveOrReject, uniqueKey }) {
  const { PatchRequest, PostRequest } = useContext(HttpContext);

  const {
    user: { roles },
  } = useContext(UserContext);

  const stepperValues = [
    { title: "Initial approval" },
    { title: "Customer registration" },
    { title: "Application review" },
    { title: "Frontier setup" },
  ];

  let backgroundColor;
  if (expanded) {
    backgroundColor = "#E7E3DF";
  } else if (isEven) {
    backgroundColor = "#FFFF";
  } else {
    backgroundColor = "#F8F8F8";
  }

  const [isAgreed, setIsAgreed] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  const handleCheckboxChange = (event) => {
    setIsAgreed(event.target.checked);
  };

  const handleApproveClick = async () => {
    const approvePayload = {
      customerId: row.id,
      status: "CUSTOMER_REGISTRATION_IN_PROGRESS",
    };

    try {
      await PatchRequest(Constants.REACT_APP_API_MODIFY_REQUEST_STATUS, approvePayload);
      onApproveOrReject("Request approved", "success", false);
    } catch (e) {
      onApproveOrReject("Failed to approve request", "failure", true);
      console.error(e);
    }
  };

  const handleRejectSubmit = async () => {
    const rejectPayload = {
      customerId: row.id,
      status: "INITIAL_APPROVAL_REJECTED",
      remarks: rejectionReason,
    };
    try {
      await PatchRequest(Constants.REACT_APP_API_MODIFY_REQUEST_STATUS, rejectPayload);
      onApproveOrReject("Request rejected", "success", false);
    } catch (e) {
      onApproveOrReject("Failed to reject request", "failure", true);
      console.error(e);
    }
    setIsRejectDialogOpen(false);
  };

  const handleResendInvitation = async () => {
    const resendInvitationPayload = {
      id: row?.id,
      companyRepresentative: row?.customerData?.businessRepresentativeDetail?.companyRepresentative,
      officialEmail: row?.customerData?.businessRepresentativeDetail?.officialEmail,
      businessEmail: row?.businessEmail,
      workflowName: "BUSINESS",
    };

    try {
      await PostRequest(Constants.REACT_APP_RESEND_INVITATION_LINK, resendInvitationPayload);
      onApproveOrReject("Invitation link resent", "success", false);
    } catch (e) {
      onApproveOrReject("Failed to resend invitation link", "failure", true);
      console.error(e);
    }
  };

  const handleRejectClick = () => {
    setIsRejectDialogOpen(true);
  };

  const handleCloseRejectDialog = () => {
    setIsRejectDialogOpen(false);
  };

  const statusToStep = (status) => {
    switch (status) {
      case "Initial approval":
        return 0;
      case "Customer registration":
        return 1;
      case "Application review":
        return 2;
      case "Frontier setup":
        return 3;
      default:
        return -1;
    }
  };

  const renderStepIcon = (activeStep, stepIndex, stepTitle, stepStatus) => {
    let icon = null;

    if (stepStatus === "In progress") {
      icon = <ClockCountdownIcon />;
    } else if (stepStatus === "Completed") {
      icon = (
        <IconButton
          disableRipple
          sx={{
            width: "34px",
            height: "34px",
            backgroundColor: "#6FB269",
            zIndex: 1500,
          }}
        >
          <DoneIcon sx={{ fontSize: "20px", color: "#FFF" }} />
        </IconButton>
      );
    } else {
      icon = <CloseIcon sx={{ color: "#FFD3CE", fontSize: "20px" }} />;
    }

    if (activeStep === stepIndex) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <IconButton
            disableRipple
            sx={{
              width: "34px",
              height: "34px",
              backgroundColor: stepStatus === "In progress" ? "#5DB5F4" : "#DD3923",
              zIndex: 1500,
            }}
          >
            {icon}
          </IconButton>
          <Grid container direction="column" width="10px">
            <Grid item sx={{ textAlign: "left", whiteSpace: "nowrap" }}>
              <Typography variant="body1">{stepTitle}</Typography>
            </Grid>
            <Grid item sx={{ textAlign: "left", whiteSpace: "nowrap" }}>
              <Typography variant="body2" color="textSecondary" fontStyle="italic">
                {stepStatus}
              </Typography>
            </Grid>
            {activeStep === 1 && stepStatus === "In progress" && (
              <Grid item sx={{ textAlign: "left", whiteSpace: "nowrap", mt: 1 }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#EA3E27",
                    color: "white",
                    textTransform: "none",
                    borderRadius: "100px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    height: "25px",
                    p: 2,
                  }}
                  onClick={handleResendInvitation}
                  data-testid={`${uniqueKey}-resendEmail-button`}
                >
                  <IconButton color="inherit" size="small">
                    <EmailOutlinedIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                  <Typography variant="body2" data-testid={`${uniqueKey}-resendEmail-msg`}>
                    Resend registration email
                  </Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      );
    }
    if (stepIndex < activeStep) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <IconButton
            disableRipple
            sx={{
              width: "34px",
              height: "34px",
              backgroundColor: "#6FB269",
              zIndex: 1500,
            }}
          >
            <DoneIcon sx={{ fontSize: "20px", color: "#FFF" }} />
          </IconButton>
          <Grid container direction="column" width="10px">
            <Grid item sx={{ textAlign: "left", whiteSpace: "nowrap" }}>
              <Typography variant="body1">{stepTitle}</Typography>
            </Grid>
            <Grid item sx={{ textAlign: "left", whiteSpace: "nowrap" }}>
              <Typography variant="body2" color="textSecondary" fontStyle="italic">
                {stepStatus}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      );
    }
    return (
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
        <IconButton disableRipple sx={{ width: "34px", height: "34px", zIndex: 1500 }}>
          <CircleIcon sx={{ fontSize: "10px", color: "#909090" }} />
        </IconButton>
        <Grid container direction="column" sx={{ pl: "8px", width: "10px" }}>
          <Grid item sx={{ textAlign: "left", whiteSpace: "nowrap" }}>
            <Typography variant="body1">{stepTitle}</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  function emailToFormattedName(email) {
    // Extract the name part before the @ symbol
    const namePart = email?.split("@")[0];

    // Replace hyphens with dot and then split the name into individual words
    const words = namePart?.replace(/-/g, ".")?.split(".");

    // Capitalize the first letter of each word and join them
    const formattedName = words?.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");

    return formattedName;
  }

  function phoneToWithCode(phone) {
    const formattedPhone = getCountryTelephoneCode(row?.customerData?.businessDetails?.businessLocation);
    return formattedPhone + " " + phone;
  }

  function circularProgressLabel(status) {
    return status === "INITIAL_APPROVAL_IN_PROGRESS" &&
      (roles?.includes(Constants.USER_ROLE.COO) || roles?.includes(Constants.USER_ROLE.ADMIN))
      ? "Awaiting Approval"
      : convertStatusToText(status);
  }

  function circularProgressLabelColor(status) {
    if (
      status === "INITIAL_APPROVAL_IN_PROGRESS" &&
      (roles?.includes(Constants.USER_ROLE.COO) || roles?.includes(Constants.USER_ROLE.ADMIN))
    ) {
      return "#FAD0AC";
    }
    if (["REJECTED", "DECLINED", "INVITATION_EXPIRED", "FAILED"].includes(extractRemainingStatusText(status))) {
      return "#FB6566";
    }

    return "#E0EEFA";
  }

  return (
    <React.Fragment key={row.id}>
      <TableRow sx={{ backgroundColor, height: "56px" }} data-testid={`${uniqueKey}-collapsed-view`}>
        <TableCell sx={{ pl: 4 }}>
          {row.status === "INITIAL_APPROVAL_IN_PROGRESS" &&
            (roles?.includes(Constants.USER_ROLE.COO) || roles?.includes(Constants.USER_ROLE.ADMIN)) && (
              <Badge color="warning" variant="dot" />
            )}
        </TableCell>
        <TableCell sx={{ pl: 4 }}>{row?.legalBusinessName}</TableCell>
        <TableCell sx={{ pl: 4 }}>
          {CountryCodeToNameMapping[row?.customerData?.businessDetails?.businessLocation] ||
            row?.customerData?.businessDetails?.businessLocation}
        </TableCell>
        <TableCell sx={{ pl: 4 }}>{row?.teamName}</TableCell>
        <TableCell sx={{ pl: 4 }}>{emailToFormattedName(row?.initiatedBy)}</TableCell>
        <TableCell sx={{ pl: 4 }}>{formatTimestamp(row?.createdAt)}</TableCell>
        <TableCell sx={{ pl: 4 }}>
          <Box display="flex" alignItems="center">
            <CircularProgress
              variant="determinate"
              value={((statusToStep(convertStatusToText(row.status)) + 1) / stepperValues.length) * 100}
              thickness={6}
              sx={{
                color: ["REJECTED", "DECLINED", "INVITATION_EXPIRED", "FAILED"].includes(
                  extractRemainingStatusText(row.status),
                )
                  ? "#FB6566"
                  : "#7FBC41",
                borderRadius: "50%",
                boxShadow: "inset 0 0 0 5.5px #d1d1d1",
              }}
            />
            <Chip
              label={circularProgressLabel(row.status)}
              sx={{
                backgroundColor: circularProgressLabelColor(row.status),
                color: "#212121",
                ml: 1,
                borderRadius: "4px",
              }}
            />
          </Box>
        </TableCell>
        <TableCell sx={{ pl: 4 }}>
          {row.status === "INITIAL_APPROVAL_IN_PROGRESS" &&
            (roles?.includes(Constants.USER_ROLE.COO) || roles?.includes(Constants.USER_ROLE.ADMIN)) && (
              <Link underline="always" variant="body2" color={"#000000"} onClick={() => onRowClick(row.id)}>
                Review
              </Link>
            )}
        </TableCell>
        <TableCell sx={{ pl: 4 }}>
          {expanded ? (
            <ExpandLessIcon onClick={() => onRowClick(row.id)} sx={{ cursor: "pointer" }} />
          ) : (
            <ExpandMoreIcon onClick={() => onRowClick(row.id)} sx={{ cursor: "pointer" }} />
          )}
        </TableCell>
      </TableRow>
      {expanded && (
        <TableRow data-testid={`${uniqueKey}-expanded-view`}>
          <TableCell colSpan={10} sx={{ padding: 0 }}>
            <Accordion expanded={expanded} sx={{ backgroundColor: "#FFFFFF", px: 2 }}>
              <AccordionDetails>
                <Box>
                  <Typography variant="h6" data-testid={`${uniqueKey}-tableRow-heading`}>
                    Progress status
                  </Typography>
                  <Grid container>
                    <Grid item xs={9}>
                      <Box sx={{ mt: 4, mb: 4 }}>
                        <Stepper
                          activeStep={statusToStep(convertStatusToText(row.status))}
                          alternativeLabel
                          connector={<QontoConnector />}
                        >
                          {stepperValues.map((step, index) => (
                            <Step key={index}>
                              <StepLabel
                                StepIconComponent={() =>
                                  renderStepIcon(
                                    statusToStep(convertStatusToText(row.status)),
                                    index,
                                    step.title,
                                    getStepStatusText(
                                      statusToStep(convertStatusToText(row.status)),
                                      index,
                                      extractRemainingStatusText(row.status),
                                    ),
                                  )
                                }
                              />
                            </Step>
                          ))}
                        </Stepper>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 1 }} data-testid={`${uniqueKey}-tableRowClientDetails-container`}>
                    <Typography variant="h6">Client details:</Typography>
                    <Grid container sx={{ mt: "16px" }}>
                      {[
                        {
                          label: "Business",
                          value: `${row.legalBusinessName}\n${
                            CountryCodeToNameMapping[row?.customerData?.businessDetails?.businessLocation] ||
                            row?.customerData?.businessDetails?.businessLocation
                          }\n${row?.customerData?.businessDetails?.businessEmail}\n${phoneToWithCode(
                            row?.customerData?.businessDetails?.businessPhone,
                          )}`,
                        },
                        {
                          label: "Primary authorised user 1",
                          value: `${row?.customerData?.businessRepresentativeDetail?.companyRepresentative}\n${
                            row?.customerData?.businessRepresentativeDetail?.role
                          }\n${row?.customerData?.businessRepresentativeDetail?.officialEmail}\n${phoneToWithCode(
                            row?.customerData?.businessRepresentativeDetail?.phone,
                          )}`,
                        },
                        {
                          label: "Primary authorised user 2",
                          value: `${row?.customerData?.secondaryBusinessRepresentativeDetail?.companyRepresentative}\n${
                            row?.customerData?.secondaryBusinessRepresentativeDetail?.role
                          }\n${
                            row?.customerData?.secondaryBusinessRepresentativeDetail?.officialEmail
                          }\n${phoneToWithCode(row?.customerData?.secondaryBusinessRepresentativeDetail?.phone)}`,
                        },
                        {
                          label: "Subscription plan",
                          value: `${row?.customerData?.subscriptionPlan?.pricingPlan}\nStarts on ${row?.customerData?.subscriptionPlan?.billingStartDate}`,
                        },
                      ].map((detail, index) => (
                        <Grid item xs={2} key={index}>
                          <Box sx={{ width: "164px" }}>
                            <Box lineHeight={1}>
                              <Typography variant="body1" sx={{ color: "rgba(33, 33, 33, 0.75)", fontSize: "14px" }}>
                                {detail.label}
                              </Typography>
                            </Box>
                            <Box sx={{ wordBreak: "keep-all", whiteSpace: "pre-wrap", lineHeight: 1.25 }}>
                              <Typography variant="body1" sx={{ fontSize: "14px" }}>
                                {detail.value}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  {row?.status === "INITIAL_APPROVAL_IN_PROGRESS" &&
                    (roles?.includes(Constants.USER_ROLE.COO) || roles?.includes(Constants.USER_ROLE.ADMIN)) && (
                      <Box sx={{ mt: "32px" }}>
                        <Stack>
                          <Typography sx={{ fontSize: "16px", color: "rgba(33, 33, 33, 0.75)" }}>Disclaimer</Typography>
                          <Typography>
                            Approval for this client signals you have reviewed all the details and you agree to proceed
                            on initiating the Frontier setup process.{" "}
                          </Typography>
                          <Typography>
                            <Checkbox
                              checked={isAgreed}
                              onChange={handleCheckboxChange}
                              color="primary"
                              sx={{ paddingLeft: 0 }}
                            />
                            Please tick if you agree and are happy to proceed.
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={2} mt={2}>
                          <Button
                            variant="outlined"
                            sx={{
                              borderRadius: "100px",
                              color: "#212121",
                              border: "1px solid rgba(0, 0, 0, 0.25)",
                              textTransform: "none",
                              width: "144px",
                            }}
                            onClick={handleRejectClick}
                            data-testid={`${uniqueKey}-reject-button`}
                          >
                            Reject
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              ml: "10px",
                              backgroundColor: "#EA3E27",
                              color: "white",
                              textTransform: "none",
                              borderRadius: "100px",
                              width: "144px",
                            }}
                            disabled={!isAgreed}
                            onClick={handleApproveClick}
                            data-testid={`${uniqueKey}-approve-button`}
                          >
                            Approve
                          </Button>
                        </Stack>
                      </Box>
                    )}
                  {row?.remarks && (
                    <Box sx={{ mt: "32px" }}>
                      <Typography
                        sx={{ fontSize: "16px", color: "rgba(33, 33, 33, 0.75)" }}
                        data-testid={`${uniqueKey}-remarks-label`}
                      >
                        Remarks
                      </Typography>
                      <Typography data-testid={`${uniqueKey}-remarks-value`}>{row?.remarks}</Typography>
                    </Box>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </TableCell>
        </TableRow>
      )}
      <Dialog
        open={isRejectDialogOpen}
        onClose={handleCloseRejectDialog}
        sx={{ zIndex: 2000 }}
        PaperProps={{ sx: { borderRadius: "8px", p: "24px" } }}
      >
        <DialogTitle data-testid="dialog-reject-title">You're about to reject this request</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: "8px", fontSize: "14px" }}>
            <Typography data-testid="dialog-rejectionReason-label">Enter reason for rejection</Typography>
          </Box>
          <FormControl fullWidth>
            <InputLabel id="dropdown-label" shrink={false}>
              {rejectionReason ? "" : "Rejection reason"}
            </InputLabel>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={rejectionReason}
              onChange={(event) => setRejectionReason(event.target.value)}
              data-testid="dialog-rejectionReason-input"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "100px",
              color: "#212121",
              border: "1px solid rgba(0, 0, 0, 0.25)",
              textTransform: "none",
              width: "91px",
            }}
            onClick={handleCloseRejectDialog}
            data-testid="rejectionDialog-cancel-button"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              ml: "10px",
              backgroundColor: "#EA3E27",
              color: "white",
              textTransform: "none",
              borderRadius: "100px",
              width: "91px",
            }}
            disabled={rejectionReason === ""}
            onClick={handleRejectSubmit}
            data-testid="rejectionDialog-submit-button"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default TableRowWithDetails;
