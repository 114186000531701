import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";

import { useTranslation } from "react-i18next";

function NotFound() {
  const [checked, setChecked] = useState(false);

  useEffect(() => setChecked(true), []);

  const { t } = useTranslation();
  return (
    <Grid container sx={{ height: "100vh" }} data-testid="notFound-page-container">
      <Grid item marginLeft="1em" marginTop="1em">
        <Typography variant="h1" data-testid="notFound-page-heading">
          {t("COMMON:NOT_FOUND")}
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          marginTop: "35vh",
        }}
      >
        <Zoom in={checked} style={{ transitionDelay: checked ? "250ms" : "0ms" }}>
          <Typography variant="h1">{t("COMMON:NOT_FOUND")}</Typography>
        </Zoom>
      </Grid>
    </Grid>
  );
}
export default NotFound;
