import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Constants from "../Constants";
import UserContext from "./UserContext";

const HttpContext = createContext();

export function HttpContextProvider({ children }) {
  const navigate = useNavigate();
  const { clearUser } = useContext(UserContext);
  // eslint-disable-next-line  no-unused-vars
  const [gsiToken, setGsiToken] = useState(null);

  const [envVariables, setEnvVariables] = useState({});
  async function HttpRequest({
    url,
    method = "GET",
    prams = null,
    token = null,
    recaptcha = null,
    fullResponse = false,
    disableRedirect = false,
  }) {
    const req = {
      headers: {
        "Content-Type": "application/json",
      },
      method,
      cache: "no-cache",
      referrerPolicy: "no-referrer",
    };
    if (prams) {
      req.body = JSON.stringify(prams);
    }
    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    if (recaptcha) {
      req.headers["g-recaptcha"] = recaptcha;
    }
    const response = await fetch(`${Constants.REACT_APP_API_ROOT}` + url, req);
    if (!response.ok) {
      switch (response.status) {
        case 401:
          clearUser();
          if (!disableRedirect) navigate("/admin");
          throw new Error("401 Unauthorized");
        case 403:
          if (!disableRedirect) navigate(Constants.FORBIDDEN_URL, { replace: true });
          throw new Error("403 Forbidden");
        default:
          return response.json().then((msg) => {
            throw new Error(`${msg.error}`);
          });
      }
    }

    if (fullResponse) {
      return response;
    }

    const data = await response
      .json()
      .then((_data) => _data)
      .catch(() => {
        return {};
      });
    return data;
  }

  async function GetRequest(url, recaptcha = null, fullResponse = false) {
    const response = await HttpRequest({ url, method: "GET", recaptcha, fullResponse });
    return response;
  }

  async function PostRequest(url, prams, recaptcha = null) {
    const response = await HttpRequest({ url, method: "POST", prams, recaptcha });
    return response;
  }

  async function PutRequest(url, prams, recaptcha = null) {
    const response = await HttpRequest({ url, method: "PUT", prams, recaptcha });
    return response;
  }

  async function PatchRequest(url, prams, recaptcha = null) {
    const response = await HttpRequest({ url, method: "PATCH", prams, recaptcha });
    return response;
  }

  async function DeleteRequest(url, prams, recaptcha = null, disableRedirect = false) {
    const response = await HttpRequest({ url, method: "DELETE", prams, recaptcha, disableRedirect });
    return response;
  }

  async function LoginRequest(url, token) {
    const response = await HttpRequest({ url, method: "POST", token });
    setGsiToken(token);
    return response;
  }

  return (
    <HttpContext.Provider
      value={{
        GetRequest,
        PostRequest,
        PutRequest,
        PatchRequest,
        DeleteRequest,
        LoginRequest,
        setEnvVariables,
        envVariables,
      }}
    >
      {children}
    </HttpContext.Provider>
  );
}

export default HttpContext;
