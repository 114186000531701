import React, { useState, useContext } from "react";
import { TableRow, TableCell, Button } from "@mui/material";
import HttpContext from "../../services/HttpContext";
import UserContext from "../../services/UserContext";
import Constants, { serviceRequestStatusType, tenantRequesType, serviceRequestStatusEnum } from "../../Constants";
import { formatTimestamp } from "../commons/Utils";
import ApprovalModal from "./ApprovalModal"; 

function TableRowWithDetails({ row, expanded, isEven, onApproveOrReject, uniqueKey }) {
  const { PostRequest } = useContext(HttpContext);
  const {
    user: { user, roles, email },
  } = useContext(UserContext);

  const [openModal, setOpenModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const handleApproveClick = () => {
    setSelectedRequest(row);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedRequest(null);
  };

  const handleApproveConfirm = async () => {
    if (selectedRequest) {
      const { id, tenantIdentifier, legalEntityName } = selectedRequest;
      await raisePauseorUnpauseRequest(
        id,
        tenantIdentifier,
        legalEntityName,
        serviceRequestStatusEnum.APPROVED,
        selectedRequest.requestType
      );
      handleModalClose();
    }
  };

  const raisePauseorUnpauseRequest = async (id, tenantIdentifier, legalEntityName, requestStatus, requestType) => {
    const pauseRequestPayload = {
      id: id,
      tenantIdentifier: tenantIdentifier,
      requestCreatedBy: user,
      legalEntityName: legalEntityName,
      requestStatus: requestStatus,
      requestType: requestType,
    };

    try {
      await PostRequest(Constants.REACT_APP_API_UPDATE_PAUSE_REQUEST, pauseRequestPayload);
      onApproveOrReject(
        `${tenantRequesType[requestType]} ${serviceRequestStatusType[requestStatus].toLowerCase()}`,
        "success",
        false
      );
    } catch (e) {
      onApproveOrReject(`Failed to ${tenantRequesType[requestType]}`, "failure", true);
      console.error(e);
    }
  };

  

  let backgroundColor;
  if (expanded) {
    backgroundColor = "#E7E3DF";
  } else if (isEven) {
    backgroundColor = "#FFFF";
  } else {
    backgroundColor = "#F8F8F8";
  }

  return (
    <React.Fragment key={row.id}>
      <TableRow sx={{ backgroundColor, height: "56px" }} data-testid={`${uniqueKey}-collapsed-view`}>
        <TableCell sx={{ pl: 4 }}>{row.legalEntityName}</TableCell>
        <TableCell sx={{ pl: 4 }}>{tenantRequesType[row.requestType]}</TableCell>
        <TableCell sx={{ pl: 4 }}>{row.requestCreatedBy}</TableCell>
        <TableCell sx={{ pl: 4 }}>{formatTimestamp(row.requestCreatedOn)}</TableCell>
        <TableCell sx={{ pl: 4 }}>{serviceRequestStatusType[row.requestStatus]}</TableCell>
        <TableCell sx={{ pl: 4 }}>
          <Button
            variant="primary"
            disabled={
              row.requestStatus !== serviceRequestStatusEnum.PENDING ||
              (!roles?.includes(Constants.USER_ROLE.COO) && !roles?.includes(Constants.USER_ROLE.ADMIN)) ||
              (email === row.requestCreatedBy && !roles?.includes(Constants.USER_ROLE.ADMIN))
            }
            sx={{
              borderRadius: "100px",
              color: "#212121",
              border: "1px solid rgba(0, 0, 0, 0.25)",
              textTransform: "none",
            }}
            onClick={handleApproveClick}
          >
            Approve
          </Button>
        </TableCell>
        <TableCell sx={{ pl: 4 }}>
          <Button
            variant="primary"
            disabled={
              row.requestStatus !== serviceRequestStatusEnum.PENDING ||
              roles?.includes(Constants.USER_ROLE.SE) ||
              (!roles?.includes(Constants.USER_ROLE.ADMIN) && email === row.requestCreatedBy)
            }
            sx={{
              borderRadius: "100px",
              color: "#212121",
              border: "1px solid rgba(0, 0, 0, 0.25)",
              textTransform: "none",
            }}
            onClick={() => {
              raisePauseorUnpauseRequest(
                row.id,
                row.tenantIdentifier,
                row.legalEntityName,
                serviceRequestStatusEnum.REJECTED,
                row.requestType,
              );
            }}
          >
            Reject
          </Button>
        </TableCell>
      </TableRow>

      <ApprovalModal
        open={openModal}
        onClose={handleModalClose}
        onApprove={handleApproveConfirm}
      />
    </React.Fragment>
  );
}

export default TableRowWithDetails;
