import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

const AuthorizedUserForm = ({
  userKey,
  state,
  dispatch,
  handleInputChange,
  validationErrors,
  validateRepresentativeEmails,
  validateRepresentativePhones,
  roleOptions,
  startAdornment,
}) => {
  const user = state[userKey];

  return (
    <>
      <Typography
        gutterBottom
        sx={{ fontSize: "16px", fontWeight: 700, mt: "40px" }}
        data-testid={`${userKey}-authUserForm-heading`}
      >
        {userKey === "primary" ? "Primary Authorized User 1" : "Primary Authorized User 2"}
      </Typography>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="firstname"
              label="First Name"
              fullWidth
              variant="outlined"
              value={user.firstname}
              onChange={(e) => dispatch({ type: "updateNestedField", parent: userKey, event: e.target })}
              data-testid={`${userKey}AuthUserForm-firstname-input`}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="lastname"
              label="Last Name"
              fullWidth
              variant="outlined"
              value={user.lastname}
              onChange={(e) => dispatch({ type: "updateNestedField", parent: userKey, event: e.target })}
              data-testid={`${userKey}AuthUserForm-lastname-input`}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Role</InputLabel>
            <Select
              name="role"
              label="Role"
              value={user.role}
              onChange={(e) => dispatch({ type: "updateNestedField", parent: userKey, event: e.target })}
              data-testid={`${userKey}AuthUserForm-role-input`}
            >
              {roleOptions.map((option) => (
                <MenuItem key={option.value} value={option.value} data-testid={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <Typography
              variant="subtitle2"
              fontSize="12px"
              color="#49454F"
              data-testid={`${userKey}AuthUserForm-role-msg`}
            >
              Enter the role of the authorized representative
            </Typography>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="officialEmail"
              label="Official email address"
              fullWidth
              variant="outlined"
              value={user.officialEmail}
              onChange={(e) => {
                dispatch({ type: "updateNestedField", parent: userKey, event: e.target });
                validateRepresentativeEmails(userKey, e.target.value);
                handleInputChange(`${userKey}OfficialEmail`, e.target.value);
              }}
              error={!!validationErrors[`${userKey}OfficialEmail`] || !!validationErrors.repEmailDupe}
              helperText={validationErrors[`${userKey}OfficialEmail`] || validationErrors.repEmailDupe}
              data-testid={`${userKey}AuthUserForm-officialEmail-input`}
            />
            {!validationErrors[`${userKey}OfficialEmail`] && !validationErrors.repEmailDupe && (
              <Typography
                variant="subtitle2"
                fontSize="12px"
                color="#49454F"
                data-testid={`${userKey}AuthUserForm-officialEmail-msg`}
              >
                Enter the official email ID of the authorized personnel. This email will be used as primary source for
                communication.
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="representativePhoneNumber"
              label="Phone number"
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: startAdornment,
              }}
              value={user.representativePhoneNumber}
              onChange={(e) => {
                dispatch({
                  type: "updateNestedField",
                  parent: userKey,
                  event: e.target,
                });
                validateRepresentativePhones(userKey, e.target.value);
                handleInputChange(`${userKey}PhoneNumber`, e.target.value);
              }}
              error={!!validationErrors[`${userKey}PhoneNumber`] || !!validationErrors.repPhoneDupe}
              helperText={validationErrors[`${userKey}PhoneNumber`] || validationErrors.repPhoneDupe}
              data-testid={`${userKey}AuthUserForm-repPhoneNumber-input`}
            />
            {!validationErrors[`${userKey}PhoneNumber`] && !validationErrors.repPhoneDupe && (
              <Typography
                variant="subtitle2"
                fontSize="12px"
                color="#49454F"
                data-testid={`${userKey}AuthUserForm-repPhoneNumber-msg`}
              >
                Enter the phone number of the authorized representative
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="comment"
              label="Comments/notes"
              fullWidth
              variant="outlined"
              multiline
              rows={2}
              value={user.comment}
              onChange={(e) => dispatch({ type: "updateNestedField", parent: userKey, event: e.target })}
              data-testid={`${userKey}AuthUserForm-comment-input`}
            />
            <Typography
              variant="subtitle2"
              fontSize="12px"
              color="#49454F"
              data-testid={`${userKey}AuthUserForm-comment-msg`}
            >
              Enter some comments or notes about the authorized personnel
            </Typography>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default AuthorizedUserForm;
