import React, { useContext, useEffect, useState } from "react";
import HttpContext from "../../services/HttpContext";
import Table from "./Table";
import Constants from "../../Constants";
import UserContext from "../../services/UserContext";

function TenantsPage({ setNumberOfAwaitingRequests, filters, search, debounced, setDebounced, tabNameKey }) {
  const { GetRequest } = useContext(HttpContext);

  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchData = async () => {
    let url = `${Constants.REACT_APP_API_FETCH_TENANTS}`;

    if (search.trim() !== "") {
      url += `?search=${search}`;
    }

    const response = await GetRequest(url);

    console.log("response from tenants is", response);

    const noRecords = response?.data?.length <= 0;
    setNoRecordsFound(noRecords);
    return response?.data ?? [];
  };

  useEffect(() => {
    setNoRecordsFound(false);
  }, [search]);

  const headerColumns = [
    { id: "legalEntityName", label: "Legal entity name" },
    { id: "countryCode", label: "Country" },
    { id: "status", label: "Status" },
    { id: "action", label: "Action" },
  ];

  return (
    <div style={{ textAlign: "center" }}>
      <Table
        tabNameKey={tabNameKey}
        headerColumns={headerColumns}
        fetchData={fetchData}
        setNumberOfAwaitingRequests={setNumberOfAwaitingRequests}
        search={search}
        noRecordsFound={noRecordsFound}
        debounced={debounced}
        setDebounced={setDebounced}
      />
    </div>
  );
}

export default TenantsPage;
