import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const headerContainerStyle = {
  marginLeft: "28px",
  paddingTop: "5px",
};

function GridTitleContainer(props) {
  const { children, testid } = props;
  return (
    <Box sx={headerContainerStyle}>
      <Typography variant="h1" component="h1" data-testid={testid}>
        {children}
      </Typography>
    </Box>
  );
}

export default GridTitleContainer;
