import { useRef } from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

const outlinedInputStyle = {
  height: "40px",
  marginTop: "20px",
  marginBottom: "16px",
  marginLeft: "28px",
  width: "400px",
  borderRadius: "28px",
};

function SearchBox(props) {
  const { searchTerm, setSearchTerm, setDebounced, ...others } = props;

  const debounceTimeoutRef = useRef(null);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      setDebounced(true);
    }, 800);
  };

  return (
    <Box>
      <OutlinedInput
        sx={outlinedInputStyle}
        placeholder="Search"
        value={searchTerm}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon style={{ fontSize: "24px" }} />
          </InputAdornment>
        }
        inputProps={{
          "aria-label": "Search",
        }}
        {...others}
      />
    </Box>
  );
}

export default SearchBox;
