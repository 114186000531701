const en = {
  CLIENT_ONBOARDING: {
    CLIENT_ONBOARDING: "Client Onboarding",
  },

  APPLICATION: {
    LEFT_MENU: {
      LOGOUT: "Logout",
    },
  },
};

export default en;
