import Box from "@mui/material/Box";
import { styled } from "@mui/material";

const CustomTabIndicatorContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginLeft: "28px",
}));

export default CustomTabIndicatorContainer;
