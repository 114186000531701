import React from "react";
import { Snackbar as MuiSnackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";

function Transition(props) {
  return <Slide {...props} direction="left" />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Snackbar(props) {
  const { open, handleSnackBarClose, msg, vertical, horizontal, variant, ...rest } = props;
  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleSnackBarClose}
      anchorOrigin={{ vertical, horizontal }}
      TransitionComponent={Transition}
      key={vertical + horizontal}
      sx={{ maxWidth: "400px" }}
      {...rest}
    >
      <Alert
        onClose={handleSnackBarClose}
        severity={variant === "failure" ? "error" : "success"}
        sx={{
          width: "100%",
          backgroundColor: variant === "failure" ? "error.main" : "success.main",
          color: "primary.lightest",
          textAlign: "left",
        }}
      >
        {msg}
      </Alert>
    </MuiSnackbar>
  );
}

export default Snackbar;
