import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function ConfirmationDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose}
    PaperProps={{
        sx: {
          paddingTop: 0,
          borderRadius: '16px',
        },
      }}>
      <DialogTitle
        sx={{
          width: '420px', 
          height: '62px',
          padding: '0',
          background: 'var(--surface-lowest, #D8D2CB)',
          borderRadius: '0px 0px 0 0',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '16px',
          paddingRight: '16px',
          position: 'relative',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h6"
            sx={{
              width: '100%',
              opacity: 1,
              margin: '0',
              paddingLeft: '10px',
              paddingTop: '10px',
              paddingBottom: '4px',
            }}
          >
            Pause client?
          </Typography>
        </Box>
        <IconButton 
          onClick={onClose} 
          size="small" 
          sx={{ position: 'absolute', right: '16px', top: '16px' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          width: '420px',
          padding: '0',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '16px',
          paddingRight: '16px',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            margin: '15px 10px',
          }}
        >
          You are about to create a request to suspend this tenant from the Frontier finance platform. Once the request is approved, it will have the following consequences:
        </Typography>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body2" sx={{ marginRight: '2px', fontWeight: 'bold', marginLeft: '10px' }}>•</Typography>
            <Typography variant="body2" sx={{ margin: '2px 10px' }}>All users linked to the tenant will have read-only access to the platform, restricting them from creating new payments, mandates, and bill ingestion.</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body2" sx={{ marginRight: '2px', fontWeight: 'bold', marginLeft: '10px' }}>•</Typography>
            <Typography variant="body2" sx={{ margin: '2px 10px' }}>Existing information about bills, mandates, and payments will remain accessible.</Typography>
          </Box>
        </Box>

        <Typography
          variant="body2"
          sx={{
            margin: '10px 15px',
          }}
        >
          Are you sure you want to create a request to pause this client?
        </Typography>
        
        <Box
          sx={{
            borderTop: '1px dashed #CCCCCC',
            width: 'auto', 
            margin: '10px 0 15px',
          }}
        />
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: 'flex-end',
          padding: '0 24px 12px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px',
          }}
        >
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ 
              width: '88px',
              height: '40px',
              padding: 'var(--spacingxs) var(--spacingnone)',
              borderRadius: '20px',
              border: '1px solid var(--border-button-secondary, #31313180)',
              marginRight: '8px',
              color: 'black',
              textTransform: 'none',
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={onConfirm}
            sx={{
              width: '88px',
              height: '40px',
              padding: 'var(--spacingxs) var(--spacingnone)',
              borderRadius: '20px', 
              background: 'var(--button-primary, #E03400)',
              border: 'none', 
              color: 'white',
              textTransform: 'none',
            }}
          >
            Yes
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
