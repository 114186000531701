import React from "react";
import { Skeleton, TableCell, TableRow } from "@mui/material";

function TableSkeleton({ rowsNum, headerColumns }) {
  return [...Array(rowsNum)].map((row, index) => (
    <TableRow key={index} sx={{ height: "73px" }}>
      {headerColumns.map((column) => (
        <TableCell
          component="th"
          scope="row"
          sx={{
            width: column.width,
            pl: "32px",
          }}
        >
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      ))}
    </TableRow>
  ));
}

export default TableSkeleton;
