import { format } from "date-fns";
import { CountryCodeToNameMapping } from "../../Constants";

const getNonNullString = (line) => {
  return line || "";
};

const getAddressLine = (data) => {
  const address = [
    getNonNullString(data?.line1),
    getNonNullString(data?.line2),
    getNonNullString(data?.city),
    getNonNullString(data?.postalCode),
    getNonNullString(CountryCodeToNameMapping[data?.country.toLowerCase()]),
  ];
  return address.join(", ").replace(", , ", ", ");
};

const formatDate = (date) => {
  if (!date) {
    return "";
  }

  try {
    return format(new Date(date), "dd/MM/yyyy");
  } catch (err) {
    console.error(err);
    return "";
  }
};

const formatTimestamp = (date) => {
  if (!date) {
    return "";
  }

  try {
    return format(new Date(date), "dd/MM/yyyy hh:mm aa");
  } catch (err) {
    console.error(err);
    return "";
  }
};

// eslint-disable-next-line no-unneeded-ternary
const isControlledByAutomation = () => (window.Cypress ? true : false);

const activityLogsDateComparator = (jsonResponse1, jsonResponse2, orderedBy) => {
  const dateInString1 = jsonResponse1[orderedBy];
  const dateInString2 = jsonResponse2[orderedBy];

  const date1 = new Date(dateInString1).getTime();
  const date2 = new Date(dateInString2).getTime();

  if (date1 == null && date2 == null) {
    return 0;
  }

  if (date1 == null) {
    return -1;
  }
  if (date2 == null) {
    return 1;
  }

  return date1 - date2;
};

const activityLogsDescendingComparator = (a, b, orderBy) => {
  return b[orderBy] > a[orderBy] ? 1 : -1;
};

const getTime = (dateString) => {
  const dateParts = dateString.split(" ");
  const date = dateParts[0].split("/");
  const time = dateParts[1].split(":");
  const timeOfDay = dateParts[2];
  const hours = timeOfDay === "PM" ? parseInt(time[0]) + 12 : parseInt(time[0]);
  const dateConverted = new Date(date[2], parseInt(date[1]) - 1, date[0], hours, time[1]);
  const dateInSeconds = new Date(dateConverted).getTime();

  return dateInSeconds;
};

const dateComparator = (dateInString1, dateInString2) => {
  const date1 = getTime(dateInString1);
  const date2 = getTime(dateInString2);

  if (date1 == null && date2 == null) {
    return 0;
  }

  if (date1 == null) {
    return -1;
  }
  if (date2 == null) {
    return 1;
  }

  return date1 - date2;
};

const convertStatusToText = (status) => {
  const words = status.split("_");

  // Capitalize the first letter of the first word only
  const firstWord = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();

  // Convert the second word to lowercase
  const secondWord = words[1].toLowerCase();

  return `${firstWord} ${secondWord}`;
};

function extractRemainingStatusText(status) {
  // Split the status string by underscores to get individual words
  const words = status.split("_");

  // Take all the words after the first two words
  const remainingText = words.slice(2).join("_");

  return remainingText;
}

const convertEnumCaseToHumanReadable = (enumString) => {
  // Split the input string by underscores
  const words = enumString.toLowerCase().split("_");

  // Capitalize the first letter of the first word
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);

  // Join the words back together with spaces
  const humanReadableString = words.join(" ");

  return humanReadableString;
};

function getCountryTelephoneCode(country) {
  const countryTelephoneCodes = { IN: ["+91"], DE: ["+49"], GB: ["+44"] };
  return countryTelephoneCodes[country];
}

export {
  getAddressLine,
  getNonNullString,
  formatDate,
  formatTimestamp,
  isControlledByAutomation,
  activityLogsDateComparator,
  activityLogsDescendingComparator,
  dateComparator,
  convertStatusToText,
  extractRemainingStatusText,
  convertEnumCaseToHumanReadable,
  getCountryTelephoneCode,
};
