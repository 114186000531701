import React, { createContext, useState, useEffect } from "react";
import { setCookie, getCookie, clearCookie } from "./CookieHelper";

const UserContext = createContext();

const USER_COOKIE = "currUser";

export function UserContextProvider({ children }) {
  const [user, setUser] = useState({});

  const isUserLoggedIn = () => {
    return Object.keys(user).length > 0;
  };

  const addUserToContext = (name, email, roles) => {
    setUser({ user: name, email: email, roles: roles });
  };

  const saveUser = (token, roles) => {
    try {
      const { name, email } = getTokenComponents(token);
      setCookie(USER_COOKIE, JSON.stringify({ name, email, roles }));
      addUserToContext(name, email, roles);
    } catch (error) {
      console.error("Error saving user:", error);
    }
  };

  const getUserFromCookie = () => {
    const userCookie = getCookie(USER_COOKIE);
    return userCookie;
  };

  const clearUser = () => {
    clearCookie(USER_COOKIE);
    setUser({});
  };

  const getTokenComponents = (token) => {
    if (!token) {
      return null;
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join(""),
    );

    return JSON.parse(jsonPayload);
  };

  useEffect(() => {
    const userFromCookie = getUserFromCookie();
    if (userFromCookie) {
      const { name, email, roles } = JSON.parse(userFromCookie);
      addUserToContext(name, email, roles);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        isUserLoggedIn,
        saveUser,
        clearUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
