import { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Add as AddIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import CustomTabs from "../Tabs/CustomTabs";
import CustomTabIndicator from "../Tabs/CustomTabIndicator";
import CustomTabPanel from "../Tabs/CustomTabPanel";
import TicketGridTabIndicatorContent from "../Tabs/TicketGridTabIndicatorContent";
import CustomTabIndicatorContainer from "../Tabs/CustomTabIndicatorContainer";
import GridTitleContainer from "../commons/GridTitleContainer";
import SearchBox from "../commons/SearchBox";
import RequestsPage from "./RequestsPage";
import ServiceRequests from "../ServiceRequests/ServiceRequestsPage";
import UserContext from "../../services/UserContext";
import Constants from "../../Constants";
import TenantsPage from "../Tenants/TenantsPage";

function ClientOnboardingTabs() {
  const { t } = useTranslation();
  const {
    user: { user, roles },
  } = useContext(UserContext);

  const location = useLocation();
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(location?.state?.lastActiveTicketGridTabIndex || 0);
  const [numberOfAwaitingRequests, setNumberOfAwaitingRequests] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debounced, setDebounced] = useState(false);

  const handleButtonClick = () => {
    navigate(Constants.NEW_CLIENT_URL);
  };

  const handleTabChange = (index) => {
    setActiveIndex(index);
    setSearchTerm("");
  };
  return (
    <Box sx={{ backgroundColor: "#F8F8F7" }} data-testid="clientOnboard-page-container">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <GridTitleContainer testid="clientOnboard-grid-title">
          {t("CLIENT_ONBOARDING:CLIENT_ONBOARDING")}
        </GridTitleContainer>
        <Box sx={{ mr: 2 }}>
          <Typography variant="body1" data-testid="clientOnboard-welcome-msg">
            Welcome, {user}
          </Typography>
        </Box>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <SearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} setDebounced={setDebounced} />
        {(roles?.includes(Constants.USER_ROLE.SE) || roles?.includes(Constants.USER_ROLE.ADMIN)) && (
          <Button
            variant="contained"
            color="primary"
            sx={{
              mr: 2,
              backgroundColor: "#EA3E27",
              color: "white",
              width: "250px",
              textTransform: "none",
              borderRadius: "100px",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            onClick={handleButtonClick}
            data-testid="clientOnboard-newClientOnboard-button"
          >
            <IconButton color="inherit" size="small">
              <AddIcon />
            </IconButton>
            Onboard a new client
          </Button>
        )}
      </Stack>
      <Box sx={{ px: 0 }}>
        <CustomTabs activeIndex={activeIndex} setActiveIndex={handleTabChange}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <CustomTabIndicatorContainer>
              <CustomTabIndicator index={0} sx={{ gridArea: "tab" }}>
                <TicketGridTabIndicatorContent tabName="In progress" testid="clientOnboard-inProgress-tab" />
              </CustomTabIndicator>
              <CustomTabIndicator index={1} sx={{ gridArea: "tab" }}>
                <TicketGridTabIndicatorContent tabName="Request history" testid="clientOnboard-requestHistory-tab" />
              </CustomTabIndicator>
              <CustomTabIndicator index={2} sx={{ gridArea: "tab" }}>
                <TicketGridTabIndicatorContent tabName="Tenants" testid="clientOnboard-tenants-tab" />
              </CustomTabIndicator>
              <CustomTabIndicator index={3} sx={{ gridArea: "tab" }}>
                <TicketGridTabIndicatorContent tabName="Service requests" testid="clientOnboard-servicerequests-tab" />
              </CustomTabIndicator>
            </CustomTabIndicatorContainer>
            {(roles?.includes(Constants.USER_ROLE.COO) || roles?.includes(Constants.USER_ROLE.ADMIN)) && (
              <Typography
                variant="body1"
                align="right"
                sx={{ mr: 2, display: "flex", flexWrap: "wrap" }}
                data-testid="clientOnboard-actionRequired-msg"
              >
                <InfoIcon fontSize="small" sx={{ mr: 1 }} />
                Your action is required on {numberOfAwaitingRequests} request(s)
              </Typography>
            )}
          </Stack>
          <CustomTabPanel index={0}>
            <RequestsPage
              tabNameKey="inProgress"
              setNumberOfAwaitingRequests={setNumberOfAwaitingRequests}
              filters={[
                "INITIAL_APPROVAL_IN_PROGRESS",
                "CUSTOMER_REGISTRATION_IN_PROGRESS",
                "APPLICATION_REVIEW_IN_PROGRESS",
                "FRONTIER_SETUP_IN_PROGRESS",
              ]}
              search={searchTerm}
              debounced={debounced}
              setDebounced={setDebounced}
            />
          </CustomTabPanel>
          <CustomTabPanel index={1}>
            <RequestsPage
              tabNameKey="requestHistory"
              setNumberOfAwaitingRequests={setNumberOfAwaitingRequests}
              filters={[
                "INITIAL_APPROVAL_REJECTED",
                "CUSTOMER_REGISTRATION_FAILED",
                "APPLICATION_REVIEW_REJECTED",
                "FRONTIER_SETUP_COMPLETED",
                "FRONTIER_SETUP_FAILED",
                "CUSTOMER_REGISTRATION_REJECTED",
                "CUSTOMER_REGISTRATION_INVITATION_EXPIRED",
              ]}
              search={searchTerm}
              debounced={debounced}
              setDebounced={setDebounced}
            />
          </CustomTabPanel>

          <CustomTabPanel index={2}>
            <TenantsPage
              tabNameKey="tenants"
              setNumberOfAwaitingRequests={setNumberOfAwaitingRequests}
              filters={[]}
              search={searchTerm}
              debounced={debounced}
              setDebounced={setDebounced}
            />
          </CustomTabPanel>

          <CustomTabPanel index={3}>
            <ServiceRequests
              tabNameKey="serviceRequests"
              setNumberOfAwaitingRequests={setNumberOfAwaitingRequests}
              filters={["PAUSE_TENANT", "UNPAUSE_TENANT"]}
              search={searchTerm}
              debounced={debounced}
              setDebounced={setDebounced}
            />
          </CustomTabPanel>
        </CustomTabs>
      </Box>
    </Box>
  );
}
export default ClientOnboardingTabs;
