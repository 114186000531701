import { createTheme } from "@mui/material/styles";

const mainTheme = createTheme({
  typography: {
    fontFamily: ["Lato", "Arial"].join(", "),
    htmlFontSize: 14,
    fontSize: 14,
    body1: {
      fontSize: "1rem", // 16px
    },
    body1Bold: {
      fontSize: "1rem", // 16px
      fontWeight: 700,
    },
    body2: {
      fontSize: "0.875rem", // 14px
      fontWeight: 400,
    },
    body2Bold: {
      fontSize: "0.875rem", // 14px
      fontWeight: 700,
    },
    body3: {
      fontSize: "0.75rem", // 12px
      lineHeight: 1.5,
    },
    body3Bold: {
      fontSize: "0.75rem", // 12px
      fontWeight: 700,
    },
    body4: {
      fontSize: "0.625rem", // 10px
    },
    body4Bold: {
      fontSize: "0.625rem", // 10px
      fontWeight: 700,
    },
    h1: {
      fontSize: "1.875rem", // 30px
      lineHeight: "36px",
      fontWeight: 700,
    },
    h2: {
      fontSize: "1.5rem", // 24px
      lineHeight: "28px",
      fontWeight: 700,
    },
    h3: {
      fontSize: "1.25rem", // 20px
      fontWeight: 700,
    },
    h4: {
      fontSize: "1.125rem", // 18px
      fontWeight: 400,
    },
    h5: {
      fontSize: ".875rem", // 14px
      fontWeight: 400,
    },
    inlineText: {
      alignSelf: "center",
      whiteSpace: "nowrap",
    },
  },
  palette: {
    primary: {
      lightest: "#FFFFFF",
      lighter: "#F8F9FA",
      light: "#E3E3E3",
      main: "#D0D0D0",
      dark: "#818181",
      darker: "#404040",
    },
    text: {
      primary: "#3e3e3e",
    },
    orange: {
      main: "#EA3E27",
    },
  },
});

export default mainTheme;
