import { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Login from "./components/Login/Login";
import LeftMenu from "./components/LeftMenu/LeftMenu";
import NotFound from "./components/NotFound/NotFound";
import UserContext from "./services/UserContext";
import Leads from "./components/ClientOnboarding/ClientOnboardingTabs";
import NewLead from "./components/ClientOnboarding/NewClientOnboarding/NewClientOnboardingForm";
import mainTheme from "./themes/mainTheme";
import HttpContext from "./services/HttpContext";
import Constants from "./Constants";
import Ribbon from "./components/Ribbon/envRibbon";

const routeContainerStyle = {
  flexGrow: 1,
  height: "100vh",
  width: "100%",
  overflow: "auto",
};

function Portal() {
  return (
    <Box sx={{ display: "flex" }}>
      <LeftMenu />
      <Box sx={routeContainerStyle}>
        <Routes>
          <Route path={Constants.CLIENT_ONBOARDING_URL} element={<Leads />} />
          <Route path={Constants.NEW_CLIENT_URL} element={<NewLead />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Box>
    </Box>
  );
}

function LoginRoute() {
  return (
    <Routes>
      <Route path={Constants.CLIENT_ONBOARDING_URL} element={<Login />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
}

function App() {
  const { isUserLoggedIn } = useContext(UserContext);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
  const { setEnvVariables, GetRequest, envVariables } = useContext(HttpContext);

  useEffect(() => {
    const fetchEnvVariables = async () => {
      try {
        const response = await GetRequest(Constants.REACT_APP_API_FETCH_ENV_VARIABLE);
        setEnvVariables(response);
      } catch (error) {
        console.error("Error fetching environment variables:", error);
      }
    };

    function onLoadHandler() {
      setIsDocumentLoaded(true);
    }

    fetchEnvVariables().then(() => onLoadHandler());
  }, []);

  return (
    <>
      <ThemeProvider theme={mainTheme}>
        {!isDocumentLoaded ? (
          <div>Loading...</div>
        ) : (
          <>
            <Ribbon env={envVariables?.ENV} />
            {isUserLoggedIn() && <Portal />}
            {!isUserLoggedIn() && <LoginRoute />}
          </>
        )}
      </ThemeProvider>
    </>
  );
}
export default App;
