import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

const BusinessDetailsForm = ({
  state,
  dispatch,
  handleInputChange,
  validationErrors,
  checkUserExist,
  startAdornment,
}) => {
  return (
    <>
      <Typography
        gutterBottom
        sx={{ fontSize: "16px", fontWeight: 700, mt: "40px" }}
        data-testid="bizDetailsForm-panel-heading"
      >
        Business Details
      </Typography>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="businessName"
              label="Legal entity name"
              fullWidth
              variant="outlined"
              value={state.businessName}
              onChange={(e) => {
                dispatch({ type: "updateField", event: e.target });
                handleInputChange("businessName", e.target.value);
              }}
              error={!!validationErrors.businessName}
              helperText={validationErrors.businessName}
              data-testid="bizDetailsForm-businessName-input"
            />
            <Typography
              variant="subtitle2"
              fontSize="12px"
              color="#49454F"
              data-testid="bizDetailsForm-businessName-msg"
            >
              Enter the company's official name that appears on government and legal forms
            </Typography>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="teamName"
              label="Business name on frontier"
              fullWidth
              variant="outlined"
              value={state.teamName}
              onChange={(e) => dispatch({ type: "updateField", event: e.target })}
              data-testid="bizDetailsForm-teamName-input"
            />
            <Typography variant="subtitle2" fontSize="12px" color="#49454F" data-testid="bizDetailsForm-teamName-msg">
              Enter company's name to be represented on Frontier
            </Typography>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="businessEmailAddress"
              label="Business email address"
              fullWidth
              variant="outlined"
              value={state.businessEmailAddress}
              onChange={(e) => {
                dispatch({ type: "updateField", event: e.target });
                handleInputChange("businessEmailAddress", e.target.value);
              }}
              onBlur={(e) => {
                checkUserExist(e.target.name);
              }}
              error={!!validationErrors.businessEmailAddress || !!validationErrors.businessEmailExists}
              helperText={
                validationErrors.businessEmailAddress
                  ? validationErrors.businessEmailAddress
                  : validationErrors.businessEmailExists
              }
              data-testid="bizDetailsForm-bizEmail-input"
            />
            {!validationErrors.businessEmailAddress && !validationErrors.businessEmailExists && (
              <Typography variant="subtitle2" fontSize="12px" color="#49454F" data-testid="bizDetailsForm-bizEmail-msg">
                Enter company's main email address (group or shared). Do not enter personal emails.
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <TextField
              name="businessPhoneNumber"
              label="Business phone number"
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: startAdornment,
              }}
              value={state.businessPhoneNumber}
              onChange={(e) => {
                dispatch({ type: "updateField", event: e.target });
                handleInputChange("businessPhoneNumber", e.target.value);
              }}
              error={!!validationErrors.businessPhoneNumber}
              helperText={validationErrors.businessPhoneNumber}
              data-testid="bizDetailsForm-bizPhone-input"
            />
            {!validationErrors.businessPhoneNumber && (
              <Typography variant="subtitle2" fontSize="12px" color="#49454F" data-testid="bizDetailsForm-bizPhone-msg">
                Enter the company&rsquo;s main phone number
              </Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default BusinessDetailsForm;
