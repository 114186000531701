import React, { useState, useContext } from 'react';
import { TableRow, TableCell, Button } from '@mui/material';
import HttpContext from '../../services/HttpContext';
import UserContext from '../../services/UserContext';
import Constants, { CountryCodeToNameMapping, TenantStatusType, TenantStatusEnum } from '../../Constants';
import ConfirmationDialog from './ConfirmationDialog';

function TableRowWithDetails({ row, expanded, onRowClick, isEven, onApproveOrReject, uniqueKey }) {
  const { PostRequest } = useContext(HttpContext);
  const {
    user: { roles, email },
  } = useContext(UserContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState('');

  let backgroundColor;
  if (expanded) {
    backgroundColor = '#E7E3DF';
  } else if (isEven) {
    backgroundColor = '#FFFF';
  } else {
    backgroundColor = '#F8F8F8';
  }

  const requestTypeText = {
    PAUSE_TENANT: 'Pause request',
    UNPAUSE_TENANT: 'Unpause request',
  };

  const raisePauseRequest = async (tenantIdentifier, requestType, legalEntityName) => {
    const pauseRequestPayload = {
      tenantIdentifier: tenantIdentifier,
      requestType: requestType,
      requestCreatedBy: email,
      legalEntityName: legalEntityName,
    };

    try {
      await PostRequest(Constants.REACT_APP_API_CREATE_PAUSE_REQUEST, pauseRequestPayload);
      onApproveOrReject(`${requestTypeText[requestType]} raised for tenant`, 'success', false);
    } catch (e) {
      onApproveOrReject(`Failed to raise ${requestTypeText[requestType]}`, 'failure', true);
      console.error(e);
    }
  };

  const handleConfirm = () => {
    if (currentAction === 'PAUSE') {
      raisePauseRequest(row.tenantIdentifier, 'PAUSE_TENANT', row.legalEntityName);
    }
    setIsDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setCurrentAction('PAUSE');
    setIsDialogOpen(true);
  };

  return (
    <React.Fragment key={row.id}>
      <TableRow sx={{ backgroundColor, height: '56px' }} data-testid={`${uniqueKey}-collapsed-view`}>
        <TableCell sx={{ paddingLeft: '2.5rem' }}>{row.legalEntityName}</TableCell>
        <TableCell sx={{ paddingLeft: '2.5rem' }}>{CountryCodeToNameMapping[row.countryCode]}</TableCell>
        <TableCell sx={{ paddingLeft: '2.5rem' }}>{TenantStatusType[row.status]}</TableCell>
        <TableCell sx={{ paddingLeft: '2.5rem' }}>
          {row.status === TenantStatusEnum.PAUSED ? (
            <Button
              variant="primary"
              disabled={
                row.status === TenantStatusEnum.INACTIVE ||
                (!roles?.includes(Constants.USER_ROLE.SE) && !roles?.includes(Constants.USER_ROLE.ADMIN))
              }
              sx={{
                borderRadius: '100px',
                color: '#212121',
                border: '1px solid rgba(0, 0, 0, 0.25)',
                textTransform: 'none',
              }}
              onClick={() => {
                raisePauseRequest(row.tenantIdentifier, 'UNPAUSE_TENANT', row.legalEntityName);
              }}
            >
              Unpause
            </Button>
          ) : (
            <Button
              variant="primary"
              disabled={
                row.status === TenantStatusEnum.INACTIVE ||
                (!roles?.includes(Constants.USER_ROLE.SE) && !roles?.includes(Constants.USER_ROLE.ADMIN))
              }
              sx={{
                borderRadius: '100px',
                color: '#212121',
                border: '1px solid rgba(0, 0, 0, 0.25)',
                textTransform: 'none',
              }}
              onClick={handleOpenDialog}
            >
              Pause
            </Button>
          )}
        </TableCell>
      </TableRow>

      <ConfirmationDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={handleConfirm}
        message={`Are you sure you want to pause this tenant?`}
      />
    </React.Fragment>
  );
}

export default TableRowWithDetails;
