import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Constants from "../../Constants";
import HttpContext from "../../services/HttpContext";
import UserContext from "../../services/UserContext";
import { ReactComponent as FrontierLogo } from "../../icons/frontier_logo.svg";

// Styled Components
const LoginContainer = styled(Box)({
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "#E8E3DE",
  boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.15)",
});

const LoginForm = styled(Paper)({
  width: "448px",
  backgroundColor: "#EDEAE7",
  color: "#212121",
  border: "1px solid #CCC",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  borderRadius: "20px",
});

const LogoContainer = styled("div")({
  marginBottom: "80px",
  marginTop: "40px",
  width: "328px",
});

const CopyRightText = styled(Typography)({
  fontSize: "12px",
  color: "#000000",
  marginTop: "24px",
});

const Divider = styled("div")({
  borderBottom: "1px dashed #707070",
  opacity: "0.4",
  width: "328px",
  margin: "1em 0",
});

export default function Login() {
  const navigate = useNavigate();
  const { LoginRequest, GetRequest, envVariables } = useContext(HttpContext);
  const { saveUser } = useContext(UserContext);

  // Function to handle Google Sign-In callback response
  function handleGoogleSigninCallbackResponse(response) {
    const jwtToken = response.credential;
    LoginRequest(Constants.REACT_APP_API_LOGIN, jwtToken)
      .then(() => GetRequest(Constants.REACT_APP_API_FETCH_ROLES))
      .then((roles) => {
        saveUser(jwtToken, roles?.roles);
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (params.redirectTo) {
          navigate(params.redirectTo);
        } else {
          navigate(Constants.CLIENT_ONBOARDING_URL);
        }
      })
      .catch((err) => console.error(`Failed to fetch user roles: ${err.message}`));
  }

  useEffect(() => {
    if (!window.google) {
      return;
    }

    // Google Sign-In initialization
    window.google.accounts.id.initialize({
      client_id: envVariables?.GOOGLE_CLIENT_ID,
      callback: handleGoogleSigninCallbackResponse,
    });

    window.google.accounts.id.renderButton(document.getElementById("google-signin-btn-container"), {
      theme: "filled_blue",
      size: "large",
    });
  }, []);

  return (
    <LoginContainer>
      <LoginForm>
        <LogoContainer>
          <Box sx={{ mb: "33px" }} data-testid="adminLogin-logo-container">
            <FrontierLogo />
          </Box>
          <Typography variant="h1" data-testid="adminLogin-frontier-heading">
            Administrator
          </Typography>
          <Divider />
        </LogoContainer>
        <Box id="google-signin-btn-container" data-testid="adminLogin-googleSignin-button" pb="160px" />
      </LoginForm>
      <Box sx={{ width: "450px", marginTop: "24px" }}>
        <Typography variant="body2" sx={{ opacity: "0.4" }} data-testid="adminLogin-disclaimer-text">
          Disclaimer: Access to this service is prohibited unless authorized. Accessing programs or data unrelated to
          your job is prohibited.
        </Typography>
      </Box>
      <Box sx={{ width: "448px", border: "1px dashed #707070", opacity: "0.4", marginTop: "24px" }} />
      <CopyRightText data-testid="adminLogin-copyRight-text">&copy; 2023 Frontier. All Rights Reserved.</CopyRightText>
    </LoginContainer>
  );
}
