import React from "react";
import { Typography } from "@mui/material";

const NoRecords = () => {
  return (
    <>
      <Typography
        variant="h6"
        color="textSecondary"
        height="479px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        No records found
      </Typography>
    </>
  );
};

export default NoRecords;
