import React from "react";
// import { envColorMap } from './envColorMap';
import { getColors } from "./envColorMap";
import StyledBox from "./stryleBox";
import { Typography } from "@mui/material";
import { isEnvInvalid } from "./isEnvInvalid";
const Ribbon = ({ env }) => {
  if (isEnvInvalid(env)) return null;
  const { color, backgroundColor, envName } = getColors(env);
  return (
    <StyledBox color={color} backgroundColor={backgroundColor}>
      <Typography>{envName}</Typography>
    </StyledBox>
  );
};

export default Ribbon;
