import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";

const TabNameContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

function TicketGridTabIndicatorContent(props) {
  const { tabName, testid = "" } = props;
  return (
    <Box>
      <TabNameContainer>
        <Typography variant="body1" data-testid={testid}>
          {tabName}
        </Typography>
      </TabNameContainer>
    </Box>
  );
}

export default TicketGridTabIndicatorContent;
