import React from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const BusinessLocationForm = ({ state, dispatch }) => {
  return (
    <>
      <Typography gutterBottom sx={{ fontSize: "16px", fontWeight: 700 }} data-testid="bizLocationForm-panel-heading">
        Business location
      </Typography>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel data-testid="bizLocationForm-bizLocation-label">Business location</InputLabel>
            <Select
              name="businessLocation"
              label="Business location"
              value={state.businessLocation}
              onChange={(e) => dispatch({ type: "updateField", event: e.target })}
              data-testid="bizLocationForm-bizLocation-input"
            >
              <MenuItem value="IN" data-testid="bizLocation-option-india">
                India
              </MenuItem>
              <MenuItem value="DE" data-testid="bizLocation-option-germany">
                Germany
              </MenuItem>
            </Select>
            <Typography
              variant="subtitle2"
              fontSize="12px"
              color="#49454F"
              data-testid="bizLocationForm-bizLocation-msg"
            >
              Select the company's registered business location
            </Typography>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default BusinessLocationForm;
