import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { UserContextProvider } from "./services/UserContext";

import "./index.css";
import "./reset.css";
import App from "./App";
import i18n from "./i18n";
import { HttpContextProvider } from "./services/HttpContext";

// Don't delete this log, it is used to identify which version is currently deployed.
console.log("Admin Portal Version -", process.env.REACT_APP_VERSION);

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <UserContextProvider>
        <BrowserRouter>
          <HttpContextProvider>
            <App />
          </HttpContextProvider>
        </BrowserRouter>
      </UserContextProvider>
    </I18nextProvider>
  </React.StrictMode>,
);

reportWebVitals();
