import { useState } from "react";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import LeftMenuItems from "./LeftMenuItems";
import Box from "@mui/material/Box";
import { ReactComponent as SynthixLogo } from "../../icons/synthix-logo.svg";

import useOutsideClick from "../../hooks/useOutsideClick";
// https://www.telerik.com/blogs/how-to-build-recursive-side-menu-react
const drawerWidth = 260;

export const openedMixin = () => ({
  width: drawerWidth,
  boxShadow: "3px 0 6px 0 rgba(216, 210, 203, 0.75)",
  backgroundColor: "#E8E3DE",
});

export const closedMixin = () => ({
  width: "70px",
  boxShadow: "3px 0 6px 0 rgba(216, 210, 203, 0.75)",
  backgroundColor: "#E8E3DE",
});

const StyledMuiDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
    },
  }),
}));

function LeftMenu() {
  const [open, setOpen] = useState(false);

  const closeDrawer = () => setOpen(false);
  const outsideClickRef = useOutsideClick(closeDrawer);
  return (
    <StyledMuiDrawer variant="permanent" open={open} ref={outsideClickRef}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <IconButton
          sx={{ padding: "0", marginLeft: "2px", height: "24px", width: "24px" }}
          onClick={() => setOpen((prev) => !prev)}
          data-testid="menu-drawerToggle-button"
        >
          {open ? <CloseIcon sx={{ fontSize: "24px" }} /> : <MenuIcon sx={{ fontSize: "24px" }} />}
        </IconButton>
      </Toolbar>
      <List component="nav" sx={{ height: "100%" }}>
        <LeftMenuItems open={open} setOpen={setOpen} />
      </List>
      <Box
        sx={{
          marginLeft: "2px",
          height: "24px",
          display: "flex",
          justifyContent: "center",
          paddingTop: "20px",
          paddingBottom: "20px",
          backgroundColor: "#D3CFCB",
        }}
        data-testid="menu-DB-logo"
      >
        <SynthixLogo />
      </Box>
    </StyledMuiDrawer>
  );
}

export default LeftMenu;
