import React, { useContext, useEffect, useState } from "react";
import HttpContext from "../../services/HttpContext";
import Table from "../commons/Table/Table";
import Constants from "../../Constants";

function RequestsPage({ setNumberOfAwaitingRequests, filters, search, debounced, setDebounced, tabNameKey }) {
  const { GetRequest } = useContext(HttpContext);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchData = async () => {
    let url = `${Constants.REACT_APP_API_FETCH_CLIENT_ONBOARDING_LIST}?filter=${filters}`;

    // Conditionally append search query to the URL if search is not empty
    if (search.trim() !== "") {
      url += `&search=${search}`;
    }

    const response = await GetRequest(url);

    const customers = response?.customers ?? [];
    const noRecords = customers.length <= 0;
    setNoRecordsFound(noRecords);
    return customers;
  };

  useEffect(() => {
    setNoRecordsFound(false);
  }, [search]);

  const headerColumns = [
    { id: "badge", label: "", width: "5%" },
    { id: "legalBusinessName", label: "Legal entity name", width: "15%" },
    { id: "location", label: "Location", width: "10%" },
    { id: "createdBy", label: "Name on frontier", width: "15%" },
    { id: "createdAt", label: "Request created by", width: "15%" },
    { id: "reviewer", label: "Request created on", width: "15%" },
    { id: "currentStatus", label: "Status", width: "15%" },
    { id: "action", label: "Action", width: "5%" },
    { id: "expandRow", label: "", width: "5%" },
  ];

  return (
    <div style={{ textAlign: "center" }}>
      <Table
        tabNameKey={tabNameKey}
        headerColumns={headerColumns}
        fetchData={fetchData}
        setNumberOfAwaitingRequests={setNumberOfAwaitingRequests}
        search={search}
        noRecordsFound={noRecordsFound}
        debounced={debounced}
        setDebounced={setDebounced}
      />
    </div>
  );
}

export default RequestsPage;
